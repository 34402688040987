/**
 * Mixin edellyttää että sitä hyödyntävän viewin routelle on asetettu route propsin 'vuexModuleName' arvoksi BaseSelaa-luokasta periytetyn vuex modulen tiedoston nimi.
 */

import MenuContextMixin from './MenuContextMixin'

export default {
  name: 'ViewSelaaMixin',
  mixins: [MenuContextMixin],
  props: {
    vuexModuleName: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      debounceTimeout: '',
      debounceDelay: 500,
      handleKeyPressDisabloitu: false,
      tyhjaTaulukko: false,
    }
  },
  computed: {
    currentTab: {
      get () {
        return this.$store.state[this.vuexModuleName].currentTab
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setCurrentTab`, val)
      }
    },
    items: {
      get () {
        return this.$store.state[this.vuexModuleName].items
      },
      async set (val) {
        await this.$store.dispatch(`${this.vuexModuleName}/setItems`, val)
      }
    },
    itemsTotal: {
      get () {
        return this.$store.state[this.vuexModuleName].itemsTotal
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setItemsTotal`, val)
      }
    },
    loading: {
      get () {
        return this.$store.state[this.vuexModuleName].loading
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setLoading`, val)
      }
    },
    loadingSummary: {
      get () {
        return this.$store.state[this.vuexModuleName].loadingSummary
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setLoadingSummary`, val)
      }
    },
    loadingOffCanvas: {
      get () {
        return this.$store.state[this.vuexModuleName].loadingOffCanvas
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setLoadingOffCanvas`, val)
      }
    },
    pagesTotal: {
      get () {
        return this.$store.state[this.vuexModuleName].pagesTotal
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setPagesTotal`, val)
      }
    },
    pagination: {
      get () {
        return this.$store.state[this.vuexModuleName].pagination
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setPagination`, val)
      }
    },
    searchPanelOpen: {
      get () {
        return this.$store.state[this.vuexModuleName].searchPanelOpen
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setSearchPanelOpen`, val)
      }
    },
    searchTerms: {
      get () {
        return this.$store.state[this.vuexModuleName].searchTerms
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setSearchTerms`, val)
      }
    },
    searchQuery: {
      get () {
        return this.$store.state[this.vuexModuleName].searchQuery
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setSearchQuery`, val)
      }
    },
    selectedItem: {
      get () {
        return this.$store.state[this.vuexModuleName].selectedItem
      },
      async set (val) {
        await this.$store.dispatch(`${this.vuexModuleName}/setSelectedItem`, val)
      }
    },
    showOffCanvas: {
      get () {
        return this.$store.state[this.vuexModuleName].showOffCanvas
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setShowOffCanvas`, val)
      }
    },
    tabs () {
      return this.$store.state[this.vuexModuleName].tabs
    },
    tiivisNakyma () {
      return this.$store.state.user.tiivisNakyma
    },
    paginationValues () {
      return `${this.pagination.page}${this.pagination.itemsPerPage}${this.pagination.sortBy}${this.pagination.sortDesc}`
    },
    vainYksiPaamies () {
      return this.$store.state.user.vainYksiPaamies
    },
  },
  async created() {
    await this.loadItems()
    if (!this.items || this.items.length == 0) {
      this.tyhjaTaulukko = true
    } else  {
      this.tyhjaTaulukko = false
    }
  },
  methods: {
    async loadItems (toggleLoadingSummary = true, silent = false) {
      const query = this.luoQueryParametrit().toString()
      await this.$store.dispatch(`${this.vuexModuleName}/setItems`, {
        query,
        silent,
        toggleLoadingSummary,
      })

    },
    luoQueryParametrit () {
      let query = this.luoQueryPohja()
      query = this.lisaaValitunTabinSuodattimet(query)
      query = this.lisaaHaunSuodattimet(query)
      query = this.lisaaJarjestyksenSuodattimet(query)

      return query
    },
    luoQueryPohja () {
      const query = new URLSearchParams()
      query.append('page', this.pagination.page)
      //käytään vain tyhjän taulukon saamiseen testaustarkoituksiin, älä aseta 0:ksi muuten
      //query.append('page', 0)
      query.append('psize', this.pagination.itemsPerPage)
      return query
    },
    lisaaValitunTabinSuodattimet (query) {
      if (!this.tabs) return query

      const currentTabObj = this.tabs.find(tab => tab.id == this.currentTab)
      if (currentTabObj && currentTabObj.terms) {
        currentTabObj.terms.forEach(item => {
          query.append(item.key, item.value)
        })
      }
      return query
    },
    lisaaHaunSuodattimet (query) {
      if (this.searchPanelOpen) {
        query = this.lisaaViewKohtaisetHaunSuodattimet(query)
      } else if (this.searchQuery) {
        query.append('q_haku', this.searchQuery.trim())
      }
      return query
    },
    /**
     * Ylikirjoita metodi mixiniä käyttävässä viewissä
     */
    lisaaViewKohtaisetHaunSuodattimet (query) {
      return query
    },
    kasitteleMoniosuodatin (query, monisuodatin) {
      for (const item of monisuodatin) {
        for (const term of item) {
          query.append(term.key, term.value)
        }
      }

      return query
    },
    lisaaJarjestyksenSuodattimet (query) {
      if (this.pagination.sortBy.length) {
        let order = ''

        for (const i in this.pagination.sortBy) {
          order = this.pagination.sortDesc[i] ? '-' : ''
          order += this.pagination.sortBy[i]
          query.append('order', order)
        }
      }

      return query
    },

    async refresh () {
      this.selectedItem.silent = true
      this.setSelectedItem(this.selectedItem)
    },

    handleKeyPress (e) {
      if (this.handleKeyPressDisabloitu) return

      switch (e.keyCode) {
        // Left
        case 37:
          if (this.pagination.page <= 1) return
          if (this.loading || this.showOffCanvas) return

          this.pagination.page--
          break;

        // Right
        case 39:
          if (this.pagination.page >= this.pagesTotal) return
          if (this.loading || this.showOffCanvas) return

          this.pagination.page++
          break;

        // Esc
        case 27:
          this.showOffCanvas = false

          break;
      }
    },
    setSelectedItem (item) {
      this.selectedItem = item
    },
    setShowOffCanvas (val) {
      this.showOffCanvas = val
    },
    openSearchPanel () {
      this.$vuetify.goTo('body', {
        duration: 500,
        offset: 0,
      })

      this.searchQuery = ''
      this.searchPanelOpen = true
    },
    closeSearchPanel () {
      this.searchTerms = {}

      this.searchPanelOpen = false
    },
    resetoiJaAsetaSearchQuery (hakusana) {
      try {
        const ref = this.$refs.formSearch ? this.$refs.formSearch : this.$refs.topBar.$refs.formSearch
        if (hakusana == this.searchQuery) {
          // Nollataan hakusana ja ylitetään debounce delay, jotta uusi haku varmasti laukeaa
          hakusana === '' ? ref.setSearchQuery(' ') : ref.setSearchQuery('')
          this.$nextTick(() => {
            setTimeout(() => {
              ref.setSearchQuery(String(hakusana))
            }, this.debounceDelay + 1)
          })
        } else {
          ref.setSearchQuery(String(hakusana))
        }
      } catch (e) {
        this.$sentryCaptureCustom(e)
      }
    },
  },
  watch: {
    // When pagination changes, reload items, but don't reload summary
    // FIXME pagination: {deep: true} does not work, since pagination object gets replaced with
    // same values on initial load causing data to be loaded twice.
    paginationValues: {
      async handler () {
        await this.loadItems(false)
      },
    },
    currentTab () {
      clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        this.pagination.page = 1
        this.pagesTotal = 0
        this.loadItems()
      }, this.debounceDelay)
    },
    showOffCanvas (val) {
      if (val === false) {
        this.selectedItem = { item: {} }
      }
    },
    async searchQuery () {
      this.pagination.page = 1
      this.pagesTotal = 0

      await this.loadItems()
    },
    searchTerms: {
      deep: true,
      handler () {
        clearTimeout(this.debounceTimeout)

        this.debounceTimeout = setTimeout(() => {
          this.pagination.page = 1
          this.pagesTotal = 0

          this.loadItems()
        }, (this.debounceDelay * 2)) // Asetetaan tarkennettuun hakuun pidempi viive turhien kyselyjen välttämiseksi
      }
    },
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyPress)
  },
  destroyed () {
    document.removeEventListener('keydown', this.handleKeyPress)
    this.$store.dispatch(`${this.vuexModuleName}/resetView`)
  },
}
